import { Box, Link, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Hr, Img } from 'components/common';
import { Store } from 'rdx';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHref } from 'react-router-dom';
import { Bull } from 'types';
import utils from 'utils';
import SelectableButton from '../SelectableButton';
import styles from './BullResultCard.styles';

type Props = {
  bull: Bull,
  onClick?: () => void,
  onChange?: (value: boolean) => void
}

const BullResultCard: FC<Props> = ({
  bull,
  onClick,
  onChange
}) => {
  const history = useHref(`${utils.string.printableToPathlike(bull.CRVcode?.toUpperCase())}`);
  const items = useSelector((store: Store) => store.items);
  const [ selected, setSelected ] = useState(false);
  const gtMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    const targetIndex = items.bulls.findIndex(b => b.CRVcode === bull.CRVcode);
    const imperativelySelected = items.selectedBulls.includes(targetIndex);
    setSelected(imperativelySelected);
  }, [ items, bull ]);



  return (
    <Stack
      {
        ...(
          gtMd
            ? utils.stack.flex.row('space-between', 'center')
            : utils.stack.flex.col('center', 'center')
        )
      }
      sx={ styles.container }
    >
      <Link
        { ...utils.stack.flex.row('center', 'center') }
        onClick={(e) => {
          e.preventDefault();
          onClick?.();
        }}
        href={ history }
        sx={{
          width: '100%',
          border: '2px solid transparent',
          color: '#000',
          textDecoration: 'none',
          display: 'flex',
          ':hover': {
            cursor: 'pointer',
            border: '2px solid #154775',
          }
        }}
      >
        <Stack
          { ...utils.stack.flex.col('flex-start', 'flex-start') }
          sx={ styles.pictureWrapper }          
        >
          <Stack
            { ...utils.stack.flex.col('center', 'center') }
            sx={ styles.RGDWrapper }
          >
            <Typography>{ bull.CRVcode }</Typography>
          </Stack>
          <Box sx={{
            ...utils.sx.flex.col('center', 'center'),
            width: '100%',
            overflow: 'hidden'
          }}>
            <Img
              url={
                `https://firebasestorage.googleapis.com/v0/b/\
                crv-lagoa.appspot.com/o/\
                ${utils.segmentTranslator(utils.segmentFromBullOrBreed(bull), 'pathlike').replace('-', '_')}\
                %2Fbulls%2F${bull.CRVcode}.jpg?alt=media`
                  .replace(/\s/g, '')
              }
              fallbackImg='/imgs/default.png'
              sx={{ width: '120%', aspectRatio: '3/2' }}
            />
          </Box>
        </Stack>
        <Stack
          { ...utils.stack.flex.col('flex-start', 'flex-start') }
          spacing={ 1 }
          sx={ styles.infoWrapper }
        >
          <Stack
            {
              ...(
                gtMd
                  ? utils.stack.flex.col('flex-start', 'center')
                  : utils.stack.flex.row('space-between', 'center')
              )
            }
            spacing={ gtMd ? .5 : 0}
            sx={ utils.sx.fw }
          >
            <Typography 
              variant='h3'              
              onClick={ onClick }
              sx={{ cursor: 'pointer' }}
              translate="no"
            >
              { bull?.warName != '-' ?  bull?.warName : bull.name }
            </Typography>
            <Stack { ...utils.stack.flex.row('center', 'center') } spacing={ gtMd ? 2 : .5 }>
              <Img
                url={ utils.segmentIconFromBullOrBreed(bull) }
                sx={{ width: '14px' }}
              />
              <Typography variant='body2'>{ bull.breed }</Typography>
            </Stack>
          </Stack>
          <Stack
            { ...utils.stack.flex.col('flex-start', 'flex-start') }
          >
            <Typography>{ `Registro: ${bull.registry ?? 'S/R'}` }</Typography>
            <Typography>{ `Nascimento: ${utils.string.tsToBrDate(bull.birthDate) ?? 'S/R'}` }</Typography>
            {
              'lineage' in bull &&
              !!bull.lineage &&
              <Typography>{ `Linhagem: ${bull?.lineage ?? 'S/R'}`  }</Typography>
            }
          </Stack>
        </Stack>
      </Link>
      {
        !gtMd &&
        <Hr />
      }
      <Stack
        {
          ...(
            gtMd
              ? utils.stack.flex.col('flex-end', 'center')
              : utils.stack.flex.col('center', 'center')
          )
        }
        sx={ styles.btnWrapper }
      >
        <SelectableButton
          selected={ selected }
          onClick={() => {
            setSelected(v => {
              onChange && onChange(!v);
              return !v;
            });          
          }}
        />
      </Stack>
    </Stack>
  );
};

export default BullResultCard;