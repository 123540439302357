import { Stack } from '@mui/material';
import { Img } from 'components/common';
import { FC } from 'react';
import { Segment } from 'types';
import utils from 'utils';

type Props = {
  list: string[];
  segment: Segment;
};

const StampList: FC<Props> = ({ list, segment }) => {
  return (
    <Stack
      {...utils.stack.flex.row('flex-start', 'center')}
      spacing={1}
      sx={{ width: '100%', overflowX: 'auto', marginBottom: '24px' }}
    >
      {list.map((n) => (
        <Img
          key={n}
          url={`https://firebasestorage.googleapis.com/v0/b/crv-lagoa.appspot.com/o/assets%2Fselos%2F${utils.segmentTranslator(
            segment,
            'snake'
          )}%2F${n}.png?alt=media`}
          fallbackImg="/imgs/default.png"
          sx={{ height: '50px' }}
          rmIfFail
        />
      ))}
    </Stack>
  );
};

export default StampList;
